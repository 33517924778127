import React from 'react'
import { ChevronRight, ChevronLeft } from 'src/UIComponents/Chevron/Chevron'
import PageItem from 'src/UIComponents/Pagination/PageItem'
import * as PropTypes from 'prop-types'

const ArrowPageItem = ({ onClick, direction, children }) => {
  return (
    <PageItem onClick={onClick} hideBorder>
      {direction === 'left' && <ChevronLeft small />}
      <span className='arrow-content'>{children}</span>
      {direction === 'right' && <ChevronRight small />}
    </PageItem>
  )
}

ArrowPageItem.defaultProps = {
  direction: 'right'
}

ArrowPageItem.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(['right', 'left']),
  children: PropTypes.string
}

export default ArrowPageItem
