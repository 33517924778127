import styled from 'styled-components'

export const Container = styled.div`
  width: fit-content;
  max-width: 394px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  > span {
    margin-bottom: 10px;
  }
`

export const CenteredContainer = styled.div`
  margin: auto;
  width: 254px;
  max-width: 394px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  > span {
    margin-bottom: 10px;
  }

`
export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  justify-content: space-between;
`
