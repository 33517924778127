import React, { Fragment } from 'react'
import Proptypes from 'prop-types'
import styled from 'styled-components'

import ItemMenu from './../../UIComponents/ItemMenu/ItemMenu'
import { gray } from 'src/Styles/settings/Constants'

const NavMenu = ({ links, isSellerMenu }) => (
  <Container>
    <List>
      {links && links.map((item, i) => (
        <Fragment key={i}>
          <ItemMenu isSellerMenu={isSellerMenu} to={item.to} title={item.title} />
          {item.hint}
          { !isSellerMenu && <Separator /> }
        </Fragment>
      ))}
    </List>
  </Container>
)

NavMenu.propTypes = {
  links: Proptypes.array,
  isSellerMenu: Proptypes.bool
}

const Container = styled.div`
  width: 100%;
`
const List = styled.ul`
  display: flex;
`

const Separator = styled.div`
  margin-right: 10px;
  height: 12px;
  width: 1px;
  background-color: ${gray};
  margin-left: 10px;
  margin-top: 3px;

  &:last-child {
    display: none;
  }
`

export default NavMenu
