import styled from 'styled-components'
import { white } from '../../Styles/settings/Constants'

const PageItem = styled.button`
  text-align: center;
  color: ${({ active, theme }) => (active ? white : theme.fontColor)};
  background-color: ${({ active, theme }) =>
    active ? theme.color : 'transparent'};
  cursor: ${({ active, disabled }) => {
    if (disabled) return 'not-allowed'
    else return active ? 'default' : 'pointer'
  }};
  border: none;
  border-right: ${({ disabled, hideBorder }) =>
    !(hideBorder || disabled) && 'solid 1px'};
  &:focus {
    outline: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow-content {
    margin: 0 15px;
  }
`

export default PageItem
