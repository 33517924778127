import React from 'react'
import { Modal } from '../Modal/Modal'
import { CenteredContainer } from './styles'
import Label from '../Label/Label'
import { darkerGray, bigText, smallerText } from '../../Styles/settings/Constants'
import ButtonDefault from '../Button/ButtonDefault'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const AnticipationPopupProcessing = ({ handleClose, handleSubmit, isLoading }) => {
  return (
    <Modal handleOnClose={handleClose} styleProps={{ minWidth: '314px', maxWidth: '400px', height: '388px', left: '25%', padding: '60px 30px' }} >
      <CenteredContainer>
        <Label fontSize={bigText} textAlign={'center'}><strong>Ei, precisamos de um tempinho!</strong></Label>
        <Label
          color={darkerGray}
          textAlign={'center'}
        >
          Em até 2 horas, você poderá conferir a simulação do valor líquido a ser antecipado com base nas informações fornecidas.
        </Label >

        <AlertContainer>
          <Label color={darkerGray}
            textAlign={'center'} fontSize={smallerText}>
            Lembre-se de que a simulação é válida até às 16h.
          </Label>
        </AlertContainer>

        <ButtonDefault isLoading={isLoading} widthAuto onClick={handleSubmit} >
            Ok, aguardar
        </ButtonDefault>
      </CenteredContainer >
    </Modal>
  )
}

AnticipationPopupProcessing.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

const AlertContainer = styled.div`
  width: 254px;
  margin: auto;
  margin-bottom: 10px;
  height: 80px;
  padding:28px 16px 28px 16px; 
  border-radius: 5px;
  gap: 16px;
  background-color:#F1F1F1 ;
  display: flex;
  align-items: center;
  justify-content: center;
`
