import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorLabel, PinInput, PinPadContainer, PinsWrapper } from './styles'

const defaultProps = {
  digits: 6,
  initialValue: '',
  onChange: () => { /* noop */ },
  type: 'password',
  variant: 'primary'
}

const propTypes = {
  digits: PropTypes.number,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['password', 'numeric']),
  variant: PropTypes.oneOf(['primary', 'danger']),
  error: PropTypes.string
}

export const PinPad = ({
  digits,
  initialValue,
  onChange,
  type,
  variant,
  error
}) => {
  const [value, setValue] = useState(initialValue.slice(0, digits).split(''))
  const inputRefs = Array.from(Array(digits)).map(() => useRef())

  useEffect(() => {
    document.addEventListener('paste', e => {
      const value = e.clipboardData?.getData('Text')

      if (value) {
        const isNumber = Number.isInteger(Number(value))
        if (isNumber) {
          setValue(value.split(''))
        }
      }
    })
  }, [])

  useEffect(() => {
    value && onChange(value.join(''))
  }, [value])

  const KEY_CODE_WHITE_LIST = [
    13, // ENTER
    8, // BACKSPACE
    46, // DEL
    9 // TAB
  ]

  const BACKSPACE_KEY_CODE = 8
  const DEL_KEY_CODE = 46
  const SPACE_KEY_CODE = 32

  const handleChange = (e, inputIndex, value) => {
    const result = inputRefs.map((inputRef, i) => {
      if (i === inputIndex) return value
      return inputRef.current.value || ''
    })

    setValue(result)
  }

  const handleKeyDown = (e, index) => {
    if (e.keyCode === SPACE_KEY_CODE) return

    const isValidNonNumericKey = KEY_CODE_WHITE_LIST.includes(e.keyCode)
    const isDeleteKey = [DEL_KEY_CODE, BACKSPACE_KEY_CODE].includes(e.keyCode)
    const isLastDigit = index === digits - 1
    const isFirstDigit = index === 0
    const value = e.key
    const isNumber = Number.isInteger(Number(value))

    const focusNext = () => {
      if (!isLastDigit) {
        inputRefs[index + 1].current.focus()
      }
    }

    const focusPrevious = () => {
      if (!isFirstDigit) {
        inputRefs[index - 1].current.focus()
      }
    }

    if (isValidNonNumericKey && isDeleteKey) {
      focusPrevious()
      handleChange(e, index, '')

      // if pressed key is TAB or ENTER, let html handle it...
    } else if (isNumber) {
      handleChange(e, index, value)

      focusNext()
    }
  }

  // useEffect(() => {
  //   inputRefs[0]?.current?.focus()
  // }, [])

  return (
    <PinPadContainer>
      <div>
        <PinsWrapper hasError={!!error}>
          {inputRefs.map((input, index) => {
            return (
              <PinInput
                variant={variant}
                key={index}
                value={value[index] || ''}
                autoComplete='off'
                ref={inputRefs[index]}
                maxLength={1}
                type={type === 'numeric' ? 'text' : type}
                onFocus={(e) => e.target.select()}
                onChange={() => { }}
                onKeyDown={(e) => handleKeyDown(e, index)} />
            )
          })}
        </PinsWrapper>
        {error && <ErrorLabel>{error}</ErrorLabel>}
      </div>
    </PinPadContainer>
  )
}

PinPad.defaultProps = defaultProps
PinPad.propTypes = propTypes
