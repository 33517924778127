import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { SimpleDropdown } from 'src/UIComponents/Dropdown'

import Label from 'src/UIComponents/Label/Label'

import { darkGray, smallMargin, normalText } from 'src/Styles/settings/Constants'

const PageSizeOptions = ({ pageSize, options, handleSelect, className }) => {
  return (
    <Container className={className}>
      <Label fontSize={normalText} color={darkGray}>Resultados por página</Label>
      <SimpleDropdown value={pageSize} options={options} handleSelect={handleSelect} />
    </Container>
  )
}

PageSizeOptions.defaultSize = {
  options: []
}

PageSizeOptions.propTypes = {
  pageSize: PropTypes.number,
  options: PropTypes.array,
  handleSelect: PropTypes.func,
  className: PropTypes.string
}

export { PageSizeOptions }

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${smallMargin};
  position: relative;
`
