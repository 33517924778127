import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import ReactSVG from 'react-svg'
import {
  normalText,
  red,
  green,
  gray,
  margin
} from 'src/Styles/settings/Constants'

const PasswordValidation = ({
  minCharactersStatus,
  upperAndLowercaseCharactersStatus,
  passwordsMatchStatus,
  passwordSpecialCharacterStatus,
  passwordRepeatedCharacterStatus,
  passwordRepeatedDigitStatus
}) => {
  const [minCharactersStatusIcon, setMinCharactersStatusIcon] = useState(
    '/assets/icons/system/three-dots-horizontal.svg'
  )
  const [
    upperAndLowercaseCharactersStatusIcon,
    setUpperAndLowercaseCharactersStatusIcon
  ] = useState('/assets/icons/system/three-dots-horizontal.svg')
  const [passwordsMatchStatusIcon, setPasswordsMatchStatusIcon] = useState(
    '/assets/icons/system/three-dots-horizontal.svg'
  )
  const [passwordMatchSpecialIcon, setPasswordMatchSpecialIcon] = useState(
    '/assets/icons/system/three-dots-horizontal.svg'
  )
  const [
    passwordRepeatedCharacterStatusIcon,
    setPasswordRepeatedCharacterStatusIcon
  ] = useState('/assets/icons/system/three-dots-horizontal.svg')
  const [passwordNumberStatusIcon, setPasswordNumberStatusIcon] = useState(
    '/assets/icons/system/three-dots-horizontal.svg'
  )

  const checkPassword = (switchCondition, setIcon) => {
    switch (switchCondition) {
      case 'neutral':
        setIcon('/assets/icons/system/three-dots-horizontal.svg')
        break
      case 'success':
        setIcon('/assets/icons/system/check.svg')
        break
      case 'error':
        setIcon('/assets/icons/actions/mini-close.svg')
        break
    }
  }

  useEffect(() => {
    checkPassword(minCharactersStatus, setMinCharactersStatusIcon)
  }, [minCharactersStatus])

  useEffect(() => {
    checkPassword(
      upperAndLowercaseCharactersStatus,
      setUpperAndLowercaseCharactersStatusIcon
    )
  }, [upperAndLowercaseCharactersStatus])

  useEffect(() => {
    checkPassword(passwordsMatchStatus, setPasswordsMatchStatusIcon)
  }, [passwordsMatchStatus])

  useEffect(() => {
    checkPassword(passwordSpecialCharacterStatus, setPasswordMatchSpecialIcon)
  }, [passwordSpecialCharacterStatus])

  useEffect(() => {
    checkPassword(
      passwordRepeatedCharacterStatus,
      setPasswordRepeatedCharacterStatusIcon
    )
  }, [passwordRepeatedCharacterStatus])

  useEffect(() => {
    checkPassword(passwordRepeatedDigitStatus, setPasswordNumberStatusIcon)
  }, [passwordRepeatedDigitStatus])

  return (
    <Grid noPadding marginTop={margin}>
      <Grid.Row>
        <Grid.Col cols={1}>
          <StyledSVG
            src={minCharactersStatusIcon}
            status={minCharactersStatus}
          />
        </Grid.Col>
        <Grid.Col cols={15}>
          <RequirementText status={minCharactersStatus}>
            São necessários no mínimo 12 caracteres
          </RequirementText>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={1}>
          <StyledSVG
            src={upperAndLowercaseCharactersStatusIcon}
            status={upperAndLowercaseCharactersStatus}
          />
        </Grid.Col>
        <Grid.Col cols={15}>
          <RequirementText status={upperAndLowercaseCharactersStatus}>
            São necessárias letras maiúsculas e minúsculas
          </RequirementText>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={1}>
          <StyledSVG
            src={passwordMatchSpecialIcon}
            status={passwordSpecialCharacterStatus}
          />
        </Grid.Col>
        <Grid.Col cols={15}>
          <RequirementText status={passwordSpecialCharacterStatus}>
            Deve conter pelo menos um dos caracteres @, #, $, &, *
          </RequirementText>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={1}>
          <StyledSVG
            src={passwordRepeatedCharacterStatusIcon}
            status={passwordRepeatedCharacterStatus}
          />
        </Grid.Col>
        <Grid.Col cols={15}>
          <RequirementText status={passwordRepeatedCharacterStatus}>
            Não pode conter caracteres repetidos
          </RequirementText>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={1}>
          <StyledSVG
            src={passwordNumberStatusIcon}
            status={passwordRepeatedDigitStatus}
          />
        </Grid.Col>
        <Grid.Col cols={15}>
          <RequirementText status={passwordRepeatedDigitStatus}>
            Deve haver pelo menos um número
          </RequirementText>
        </Grid.Col>
      </Grid.Row>
      {passwordsMatchStatus !== 'neutral' && (
        <Grid.Row>
          <Grid.Col cols={1}>
            <StyledSVG
              src={passwordsMatchStatusIcon}
              status={passwordsMatchStatus}
            />
          </Grid.Col>
          <Grid.Col cols={15}>
            <RequirementText status={passwordsMatchStatus}>
              As senhas precisam ser iguais
            </RequirementText>
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid>
  )
}

const statusColor = {
  error: red,
  success: green,
  neutral: gray
}

const StyledSVG = styled(ReactSVG)`
  height: 100%;

  div svg {
    height: 100%;
    fill: ${({ status }) => statusColor[status]};
  }
`

const RequirementText = styled.span`
  font-size: ${normalText};

  ${({ status }) =>
    status &&
    css`
      color: ${statusColor[status]};
    `}
`

PasswordValidation.defaultProps = {
  minCharactersStatus: 'neutral',
  upperAndLowercaseCharactersStatus: 'neutral',
  passwordsMatchStatus: 'neutral',
  passwordSpecialCharacterStatus: 'neutral',
  passwordRepeatedCharacterStatus: 'neutral'
}

PasswordValidation.propTypes = {
  minCharactersStatus: PropTypes.string,
  upperAndLowercaseCharactersStatus: PropTypes.string,
  passwordsMatchStatus: PropTypes.string,
  passwordSpecialCharacterStatus: PropTypes.string,
  passwordRepeatedCharacterStatus: PropTypes.string,
  passwordRepeatedDigitStatus: PropTypes.string
}

export default PasswordValidation
