import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const PinPadContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const PinsWrapper = styled.div`
  margin-bottom: ${({ hasError }) => hasError ? '5px' : 0};
`

PinsWrapper.propTypes = {
  hasError: PropTypes.bool
}

const PinInput = styled.input`
  text-align: center;
  width: 35px;
  height: 35px;
  font-size: 16px;
  border-radius: 3px;

  color: ${({ variant }) => {
    if (variant === 'primary') return '#555'
    if (variant === 'danger') return '#FC3D3D'
  }};

  border: 1px solid ${({ variant, value }) => {
    if (variant === 'primary') return value ? '#4E4E4E' : 'rgba(85, 85, 85, 0.3)'
    if (variant === 'danger') return '#FC3D3D'
  }};

  &:focus {
    ${({ variant }) => variant === 'primary' && css`
      border-color: #4E4E4E;
    `}
  }

  &:not(:last-of-type) {
    margin-right: 5px;
  }
`

PinInput.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.string
}

const ErrorLabel = styled.span`
  font-size: 12px;
  color: #FC3D3D;
`

export {
  PinPadContainer,
  PinsWrapper,
  PinInput,
  ErrorLabel
}
