import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

const TotalSellerToPlanLoader = (props) => (
  <Wrapper
    width={204}
    height={30}
    viewBox='0 0 204 30'
    speed={4}
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='196' height='12' />
  </Wrapper>
)

const Wrapper = styled(ContentLoader)`
  width: 100%;
  height: 100%;
  padding-top: 1.5rem;
`

export default TotalSellerToPlanLoader
