import React from 'react'
import PropTypes from 'prop-types'
import { pdfjs, Page, Document } from 'react-pdf'
import Loading from '../Dots/Dots'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFViewer = ({ file }) => {
  return (
    <Document file={file} loading={<Loading outline color={'#ccc'} />}>
      <Page
        pageNumber={1}
        width={300}
        height={150}
        styles={{ width: 300, height: 150 }}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    </Document>
  )
}

PDFViewer.propTypes = {
  file: PropTypes.any
}

export default PDFViewer
