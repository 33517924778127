import React, { useState } from 'react'
import { Modal } from '../Modal/Modal'
import { ButtonsContainer, CenteredContainer } from './styles'
import Label from '../Label/Label'
import { darkerGray, biggerText, normalText, bigText } from '../../Styles/settings/Constants'
import ButtonDefault from '../Button/ButtonDefault'
import createToast from '../../Utils/createToast'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createAnticipations } from '../../Actions/anticipations'
import { toMoney, parseDate } from '../../Utils/Utils'
import { MFASingleComponent } from '../../Components/MFA/mfaSingleComponent'

export const AnticipationPopup = ({ prepaymentId, setAnticipationPopup, anticipationValue, prepaymentDate }) => {
  const history = useHistory()
  const [ showMFAPopup, setShowMFAPopup ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  const handleAnticipation = async (status) => {
    let msg = ''
    try {
      if (status === true) {
        setIsLoading(true)
        const data = await createAnticipations(prepaymentId)

        if (data?.status === 201) {
          setAnticipationPopup(false)
          history.push('/antecipacoes')
          msg = `Sucesso! A antecipação foi <strong>realizada e estará disponível dia ${parseDate(prepaymentDate, 'DD/MM/yyyy')}.</strong>`
          createToast('success', msg)
        } else {
          const errorMsg = 'Ops...<strong>Antecipação não pode ser realizada.</strong> Tente novamente.'
          createToast('error', errorMsg)
        }
        setIsLoading(false)
      } else {
        setAnticipationPopup(false)
      }
    } catch (e) {
      const errorMsg = 'Ops...<strong>Antecipação não pode ser realizada.</strong> Tente novamente.'
      createToast('error', errorMsg)
    }
  }

  if (showMFAPopup) {
    return (
      <MFASingleComponent showComponent={setShowMFAPopup} onSubmit={() => handleAnticipation(true)} />
    )
  }

  return (
    <Modal handleOnClose={() => setAnticipationPopup(false)} styleProps={{ minWidth: '254px', maxWidth: '400px', height: 'auto', left: '25%', padding: '60px 30px' }} >
      <CenteredContainer>
        <Label fontSize={biggerText} textAlign={'center'}><strong>Você está antecipando o valor {toMoney(anticipationValue / 100)} e estará disponível em {parseDate(prepaymentDate, 'DD/MM/yyyy')}</strong></Label>
        <Label
          fontSize={normalText}
          color={darkerGray}
          textAlign={'center'}
          width={'100%'}
        >
          Certifique-se de que todos os dados foram<strong> preenchidos corretamente </strong>e os valores estão<strong> dentro da expectativa do Estabelecimento Comercial.</strong>
        </Label >

        <Label
          fontSize={bigText}
          color={darkerGray}
          textAlign={'center'} >Deseja continuar?
        </Label>

        <Label fontSize={'12px '}
          color={darkerGray}
          width={'100%'}
          textAlign={'center'} > <AlertContainer>Essa ação não poderá ser revertida!</AlertContainer>
        </Label>

        <ButtonsContainer auto>
          <ButtonDefault disabled={isLoading} widthAuto outline ghost onClick={() => handleAnticipation(false)} >
                Não antecipar
          </ButtonDefault>
          <ButtonDefault isLoading={isLoading} widthAuto onClick={() => setShowMFAPopup(true)}>
                Sim, antecipar
          </ButtonDefault>
        </ButtonsContainer>
      </CenteredContainer >
    </Modal>
  )
}

AnticipationPopup.propTypes = {
  setAnticipationPopup: PropTypes.func,
  anticipationValue: PropTypes.string,
  prepaymentId: PropTypes.string,
  prepaymentDate: PropTypes.string
}

const AlertContainer = styled.div`
  padding: 16px, 28px, 16px, 28px;
  border-radius: 5px;
  gap: 16px;
  height: 48px;
  background-color:#F1F1F1 ;
  display: flex;
  align-items: center;
  justify-content: center;
`
