import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import Title from 'src/UIComponents/Title/Title'
import Label from 'src/UIComponents/Label/Label'
import { darkGray, spaceMargin } from 'src/Styles/settings/Constants'
import Button from 'src/UIComponents/Button/ButtonDefault'
import TotalSellerToPlanLoader from 'src/UIComponents/PlanDetails/TotalSellerToPlanLoader'

const PlanDetailsHeader = ({ planName, totalSellers, setShowSidebar, isLoading }) => (
  <Grid.Row>
    <Grid.Col offset={1} cols={10}>
      <Title big noPadding>
        <strong>{planName}</strong>
      </Title>
    </Grid.Col>
    <Grid.Col cols={5}>
      <AssociatedSellersContainer>
        <AssociatedSellersLabel>
          {isLoading && <TotalSellerToPlanLoader />}
          {!isLoading && (
            <>
              <StyledLabel bold baseline color={darkGray} data-test='config-plano-qty-sellers'>
                {totalSellers}
              </StyledLabel>
              <Label bold color={darkGray}>
                {totalSellers === 1 ? 'estabelecimento associado' : 'estabelecimentos associados'}
              </Label>
            </>
          )}
        </AssociatedSellersLabel>
        <StyledButton
          data-tracking={`Config > Plans > Detail > ListSeller`}
          isLoading={isLoading}
          widthAuto
          inversed
          onClick={() => setShowSidebar(true)} disabled={totalSellers === 0}
          data-test='config-plano-verEstabelecimentos-btn'
        >
          Ver Estabelecimentos
        </StyledButton>
      </AssociatedSellersContainer>
    </Grid.Col>
  </Grid.Row>
)

const AssociatedSellersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledLabel = styled(Label)`
  margin-right: ${spaceMargin};
`

const AssociatedSellersLabel = styled.div`
  display: flex;
  flex-basis: 50%;
`

const StyledButton = styled(Button)`
  flex-basis: 50%;
`

PlanDetailsHeader.propTypes = {
  planName: PropTypes.string,
  totalSellers: PropTypes.number,
  setShowSidebar: PropTypes.func,
  isLoading: PropTypes.bool
}

export default PlanDetailsHeader
